@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';

.glpNavContainer {
    margin: 24px 0;
    min-height: 88px;
    padding-top: 24px;
    width: 100%;
}

.offers {
    color: $colour-offer-red;
}
